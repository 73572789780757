<template>
  <div class="page_box">
    <div class="ai_human" id="nextcas">
    <!-- <div class="no_content" v-if="stat==0" @click="updateMessage"> {{'点击加载模型'}}</div> -->
    <!-- <div class="no_content" v-if="stat==1"> {{loadProgress}}</div> -->
    </div>
    <!--  -->
    <div class="button_box" v-if="stat==2">
      <button @click="instr" title="开场白">A</button>
      <button @click="streamIntr" title="长文字介绍">B</button>
      <button @click="stopIntr" title="文字介绍打断">C</button>
      <button @click="changeCreame" title="切换视角">D</button>
    </div>
    <div class="input_box">
      <input v-model="message">
      <button @click="updateMessage" title="点击更新token">{{stat==0?'渲染':stat==1?loadProgress+'%':'重载'}}</button>
    </div>
    <div class="baidu_box" @click="baidu">调用baidu形象定制</div>
    <!-- 自定义数字人 -->
    <!-- <div class="human_box">
      <video ref="video" playsinline style="display:none;"></video>
      <canvas ref="canvas"></canvas>
      <button>说话</button>
    </div> -->
  </div>
</template>

<script>
let nextCas = null;
import CryptoJS from 'crypto-js';
import NextCas from "@nextcas/sdk";
// import { darkTheme } from 'naive-ui' onMounted
import {ref } from 'vue'
// import { FaceMesh } from '@mediapipe/face_mesh';
// import { Camera } from '@mediapipe/camera_utils';
export default {
  name: "homePage",
  props: {
    msg: String,
  },
  // mounted(){

  // console.log('nextCas',nextCas)
  // },
  setup() {
    const message = ref('next67188d0cfaf13c33cfbdcf66@eyJhbGciOiJIUzI1NiJ9.eyJyb2xlIjoicm9sZS52aXNpdCIsInZpc2l0SWQiOiIxMDM3OTgiLCJ2aXNpdE5hbWUiOiIxMDM3OTgiLCJ0aW1lc3RhbXAiOjE3Mjk4NDcwMzY1MjYsIm5vbmNlc3RyIjoiZGY5YTY0ODlmMDZkYzM4OGRlMTQwZmU1NzgxY2Q0NjgiLCJleHAiOjE3Mjk5MzM0MzZ9.e2O-LrMHHH6wOC-B9ZuiEVLJDKtCT8eTTgbmsnyovrA');
    const loadProgress=ref(0)
    const stat=ref(0)//加载状态 stat 0-未加载 1-加载中 2-已加载

    //初始化加载数字人
    function updateMessage() {
      const container = document.getElementById("nextcas");
      nextCas = new NextCas(container, {
        token: message.value,
        actorId: '641811add41a3f2f91247af5',
        templateName: "base",
        avatarId: 'avatar_257',
        //'avatar_501899' 自定义
      });
      nextCas.on("initProgress", (cent) => {
        loadProgress.value = cent;
        stat.value=1
        console.log('当前加载进程',loadProgress.value)
      });
      nextCas.on("ready", () => {
        stat.value=2
        setTimeout(() => {
          nextCas.speak("你好，请问有什么可以帮您");
          console.log('stat',stat,stat.value);
        });
      });
      }

      //进行简短介绍
      function instr(){
        nextCas.speak("你好，请问有什么可以帮您");
      }

      //流式介绍
    function streamIntr(){
      const stream = nextCas?.createSpeackStream();
      stream.next("烟台社会治理的建设经验主要包括以下几个方面：");
      stream.next(" 1. 建立健全四级架构三级联动社会治理工作体系，实现资源的整合和优化配置。通过市级统筹调度、县域特色运行，推动市、区、镇街、社区、村多级共建、共享、协同联动");
      stream.next("形成现代化的烟台市域社会治理体系。");
      stream.next("2. 发挥运营商云网一体及本地化优势，在较短的时间内拿下项目并实施上线。以数据为核心，构建“动态感知、数据融通、全域智能、高效协同、精确指挥”的管理模式，实现社会治理数字化、精细化、智能化");
      stream.next("3. 融合前沿技术，如5G、AI、大数据和全市实景三维等，打造全国社会治理领域的标杆。在疫情防控、矛盾纠纷化解等方面发挥重要作用，加强对综合治理的精细化管理。");
      stream.next("4. 推进智能化治理，如芝罘区利用平台对居民进行核酸检测筛查和对典型特色治理案例的总结，提高防疫效率和矛盾化解率。蓬莱市以推进市域社会治理现代化为目标，打造全科网格搭建云平台，实现党建引领、疫情防控等特色功能的整合。");
      stream.next("5. 强化网格化管理，如龙口市强化网格布局提升基层治理能力，实行四级组织管理体系，组建志愿服务和应急预备队伍，精准下沉一线支援疫情防控工作。");
      stream.next("6. 构建以党建工作为引领，社会治理工作为核心、社会信用体系为重点的基层治理新模式，如海阳市构建“党建做引领、网格化管理、多层级联动”的基层治理新格局");
      stream.last("总之，烟台在社会治理方面付出了积极的探索和实践，取得了显著的成效，为其他地区提供了有益的经验和借鉴。");
    }
    
    //打断介绍
    function stopIntr(){
      nextCas?.call("stopAct");
    }

    //切换相机
    function changeCreame(){
      nextCas?.changeCamera(
            { x: 0, y: 1.65, z: 0.58 },
            { x: 0, y: 1.61, z: -0.45 },
            500
          );
    }

    async function baidu(){
   //生成鉴权参数
   // 生成当前时间的 ISO8601 格式的过期时间
    function getExpireTime(minutes) {
      const now = new Date();
      now.setMinutes(now.getMinutes() + minutes);
      return now.toISOString();
    }

    // 生成 HMAC-SHA256 签名
    function generateSignature(appId, appKey, expireTime) {
      const message = `${appId}${expireTime}`;
      const hash = CryptoJS.HmacSHA256(message, appKey);
      return hash.toString(CryptoJS.enc.Hex); // 返回十六进制字符串
    }

    // 示例使用
    const appId = 'i-qj4v24d2jb0gc';
    const appKey = '0pg9420qx5jankynk9uj';
    const expireTime = getExpireTime(5); // 5分钟后过期

    const signature = generateSignature(appId, appKey, expireTime);

    console.log('AppId:', appId);
    console.log('ExpireTime:', expireTime);
    console.log('Signature:', signature);
    //调用接口
     console.log('xxaa');
     const url = 'https://open.xiling.baidu.com/api/digitalhuman/open/v1/figure/image/create';
    const headers = {
      'Content-Type': 'application/json;charset=utf-8',
      "Authorization": `${appId}/${signature}${expireTime}`
    };

  const body = JSON.stringify({
    "requestId": "xxxx11",
    "figureImageUrl":'http://47.103.79.171/white%E6%9C%AC.jpg',
  });
     const response = await fetch(url, {
          method: 'POST',
          headers,
          body,
        })
        if (!response.ok) {
          throw new Error('网络响应错误');
        }
        const result = await response.json();
        console.log('请求结果为',result);
        // this.data = result.slice(0, 10); // 只获取前10条数据
    }
    // 自定义图片数字人star
    // const video = ref(null);
    //     const canvas = ref(null);
    //     const ctx = ref(null);
    //     const faceMesh = new FaceMesh({
    //       locateFile: (file) => `https://cdn.jsdelivr.net/npm/@mediapipe/face_mesh/${file}`,
    //     });

    //     faceMesh.onResults(onResults);

    //     const setupCamera = async () => {
    //       const stream = await navigator.mediaDevices.getUserMedia({
    //         video: true,
    //       });
    //       video.value.srcObject = stream;

    //       return new Promise((resolve) => {
    //         video.value.onloadedmetadata = () => {
    //           resolve(video.value);
    //         };
    //       });
    //     };

    //     const init = async () => {
    //       await setupCamera();
    //       video.value.play();
    //       canvas.value.width = video.value.videoWidth;
    //       canvas.value.height = video.value.videoHeight;

    //       const camera = new Camera(video.value, {
    //         onFrame: async () => {
    //           await faceMesh.send({ image: video.value });
    //         },
    //         width: 640,
    //         height: 480,
    //       });
    //       camera.start();
    //     };

    //     const onResults = (results) => {
    //       ctx.value.clearRect(0, 0, canvas.value.width, canvas.value.height);
    //       ctx.value.drawImage(results.image, 0, 0, canvas.value.width, canvas.value.height);

    //       if (results.multiFaceLandmarks) {
    //         results.multiFaceLandmarks.forEach((landmarks) => {
    //           const mouth = landmarks.slice(61, 81); // 提取嘴部关键点

    //           // 在嘴部关键点绘制圆圈
    //           mouth.forEach((point) => {
    //             ctx.value.beginPath();
    //             ctx.value.arc(point.x * canvas.value.width, point.y * canvas.value.height, 3, 0, 2 * Math.PI);
    //             ctx.value.fillStyle = 'red';
    //             ctx.value.fill();
    //           });
    //         });
    //       }
    //     };

    //     const speak = () => {
    //       const text = "你好，我是一个动态的嘴部动画示例。";
    //       const utterance = new SpeechSynthesisUtterance(text);
    //       speechSynthesis.speak(utterance);
    //     };
        
    //     onMounted(() => {
    //       ctx.value = canvas.value.getContext('2d');
    //       init();
    //     });

      return {
                    message,
                    updateMessage,
                    instr,
                    streamIntr,
                    stopIntr,
                    changeCreame,
                    loadProgress,
                    stat,
                    baidu,
                    // video,
                    // canvas,
                    // speak,
                }
    // let showRail=ref(true);
    // let showBackground=ref(true);
    //   return {
    //     darkTheme,showRail,showBackground
    //   }
  
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.page_box {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #000;
  color: #fff;
  padding: 10px;
  display: flex;
  align-items: center;
}
.ai_human{
  position: relative;
  width: 350px;
  height:95%;
  z-index: 9998;
  border-radius: 10px;
  background-color: #ffffff44;
}
.input_box{
  position: absolute;
  width: 350px;
  height: 50px;
  bottom:40px;
  z-index: 9999;
  border-radius: 10px;
  left: 10px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-around;

}
.input_box input{
  position: relative;
  height: 35px;
  flex: 1;
  border-radius: 8px;
  }
.input_box button{
  position: relative;
  width: 35px;
  height: 35px;
  background-color: #0090ff;
  color: #fff;
  margin-left: 15px;
  border-radius:10px;
  }
.button_box{
  position:absolute;
  left: 10px;
  height:500px;
  width: 50px;
  top: 15px;
  /* background-color: #0090ff; */
  border-radius: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
  z-index: 9999;
}
.button_box button{
  width: 35px;
  height: 35px;
  background-color: #fff;
  color: #444;
  margin-top: 12px;
  border-radius: 50%;
  display: flex;
  align-items:center;
  justify-content: center;
}
.button_box button:hover{
  background-color: #0090ff;
  color: #fff;
}
.no_content{
  position: absolute;
  width:20%;
  left:40%;
  top:50%; 
}
.human_box{
  position: relative;
  width: 350px;
  height:95%;
  z-index: 9998;
  border-radius: 10px;
  background-color: #ffffff44;
  display: inline-block;
  margin-left: 10px;
}
.baidu_box{
  position: relative;
  width:100px;
  height: 80px;
  background-color: #0090ff;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}
</style>
