<template>
  <div class="page_box">

    <w-flex wrap class="text-center" style="height:100%">
    <div class="xs10 content_box">
      <!-- content_box -->
      <div class="router-title">{{computedActMenu}}</div>
      <router-view></router-view>
      <!-- <div class="primary-dark1--bg py3">xs2</div> -->
    </div>
    <!-- 路由导航star -->
    <div class="xs2 menu_foot">
      <div class="menu_box">
       <div :class="{'menu-item-act': computedActMenu === item.path, 'menu-item': true}" @click="changeActiveMenu(item)" v-for="item in computedMenuArr" :key="item.name">
       {{item.name}}
       </div>
      </div>
      <!-- <div class="primary-dark1--bg py3">xs2</div> -->
    </div>
    </w-flex>
    </div>
</template>

<script>
// import { darkTheme } from 'naive-ui'
import {ref,computed} from 'vue'
export default {
  name: 'homePage',
  props: {
    msg: String
  },
  setup() {
    let menuArr=ref([])
    let actMenu=ref('NextHuman')
    console.log('actMenu',actMenu);
    const computedMenuArr = computed(() => menuArr.value);
    const computedActMenu = computed(() => actMenu.value);

    const changeActiveMenu=((menuItem)=>{
      actMenu.value=menuItem.path
    })

    menuArr.value=[
      {name:'nexthuman',path:'NextHuman'},
      {name:'待办清单',path:'TodoList'},
      {name:'UI框架',path:'waveUI'},
      {name:'集成组件',path:'ele'}
    ]
    return {
      computedMenuArr,computedActMenu,changeActiveMenu
    }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.page_box{
position:relative;
width: 100vw;
height: 100vh;
background-color:#000;
color:#fff;
}
.showmybox{
  position: relative;
}
.redbox{
  position: relative;
  width: 100%;
  height:5em;
 }
.greenbox{
  position: relative;
  /* width: 100%;
  height:5em; */
  background-color: #F5F5F5;
 }
 .nav_head{
  position:relative;
  /* width: 100%;
  height: 5em; */
  background-color: red;
 }
 .menu_foot{
  position: relative;
  padding: 20px;
  background-color: #F5F5F5;
  border-radius: 2em 0 0 2em;
 }
 .content_box{
  position: relative;
  /* width: 100%;
  flex: 1; */
  background-color: #F5F5F5;
  padding: 5px;
  height: calc(100% - 60px);
  margin-top: 30px;
 }
 .router-title{
  position: absolute;
  bottom: 16px;
  left: 16px;
  color: #fff;
  font-size: 12px;
  z-index: 99;
 }
 .menu_box{
  position: absolute;
  width: calc(100% - 20px);
  height:calc(100% - 40px);
  /* background-color: #fa7268; */
  overflow: auto;
 }
 .menu-item{
  position: relative;
  width: calc(100% - 20px);
  padding: 8px 0;
  font-size: 12px;
  display:flex;
  align-items: center;
  justify-content: flex-start;
  border-radius:3em;
  display:flex;
  align-items: center;
  justify-content: center;
  color:#f5f5f5;
  background-color: #444;
  margin-bottom: 10px;
 }
 .menu-item:hover{
  background-color: #000;
 }
 .menu-item-act{
  background-color: #000;
  /* border:1px solid red; */
  box-shadow: 0 0 10px #44444444;
 }
</style>
